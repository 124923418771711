.survey {
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;
  margin-top: 50px;
  margin-bottom: 350px;
  font-family: 'Courier New', Courier, monospace;
  padding-left: 10%;
  padding-right: 10%;
}

.survey h2{
    /* font-family: 'Noto Sans', sans-serif;
    font-weight: 100;
    font-size: 30px;
    margin: 10px;
    text-align: left;
    color: #00909a; */

    font-size: 3em;
    margin: 0;
    text-align: center;
    font-family: 'Noto Sans', sans-serif;
    font-weight: 250;
    color: #00909a;
    font-weight: bold;
}

.survey p {
    font-size: 18px;
}

.survey-container ol{
    align-items: left;
    text-align: left;
}

.survey-container li{
    padding: 10px;
    padding-left: 20px;
    font-size: 20px;
    margin-bottom: 5px;
}

.question {
    padding: 15px;
}

input{
    padding: 5px;
    border: 2px solid #00909a;
    border-radius: 5px;
    text-decoration: none;
    font-size: 20px;
    font-family: 'Courier New', Courier, monospace;
    margin-left: 20px;
}

select{
    padding: 10px;
    border: 2px solid #00909a;
    border-radius: 5px;
    text-decoration: none;
    font-size: 16px;
    font-family: 'Courier New', Courier, monospace;
    margin-left: 20px;
}

.survey button{
    background-color: white;
    border: 2px solid #00909a;
    /* border-color: #00909a; */
    color: #00909a;
    border-radius: 5px;
    cursor: pointer;
    font-size: 20px;
    font-family: 'Courier New', Courier, monospace;
    padding: 10px;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    /* margin-left: 20px; */
    display: block;
    text-align: center;
}

.survey button:hover{
    background-color: #00909a;
    color: white;
}