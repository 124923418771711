.banner {
  /* background-color: #fffdf6; */
  margin: 0;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  font-family: "Noto Sans", sans-serif;
  font-weight: 200;
  /* font-family: 'Courier New', Courier, monospace; */
}

.banner h1 {
  font-size: 3em;
  margin: 0;
  text-align: center;
  padding: 40px;
  font-family: "Noto Sans", sans-serif;
  font-weight: 250;
  color: #00909a;
  font-weight: bold;
  overflow: hidden; /* Ensures the hidden part of the text is not visible */
  white-space: nowrap; /* Prevents the text from wrapping to the next line */
  animation: animate 1s linear forwards;
}

@keyframes animate {
  0% {
    width: 0;
    opacity: 0.25;
  }
  100% {
    width: 100%;
    opacity: 1;
  }
}

.paragraph {
  z-index: -1;
  font-size: 1.1em;
  margin: 0;
  text-align: center;
  padding: 7px;
  font-weight: 100;
  animation: animateParagraphs 1s linear forwards;
  animation-delay: 1s;
  visibility: hidden;
  margin-bottom: 20px;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.paragraph:nth-of-type(1) {
  animation-delay: 1s; /* Delay to match the title animation duration */
}

.paragraph:nth-of-type(2) {
  animation-delay: 2s; /* Starts after the first paragraph */
}

.paragraph:nth-of-type(3) {
  animation-delay: 3s; /* Starts after the second paragraph */
}

.paragraph:nth-of-type(4) {
  animation-delay: 4s; /* Starts after the second paragraph */
}

@keyframes animateParagraphs {
  0% {
    opacity: 0;
    visibility: visible;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}

/* Responsive Design */
@media (max-width: 800px) {
  .banner {
    width: 100%;
  }

  .banner h1 {
    margin-top: 200px;
    font-size: 38px;
    padding: 20px;
  }

  .banner p {
    font-size: 16px;
    padding: 5px;
  }
}

@media (max-width: 480px) {
  .banner h1 {
    margin-top: 175px;
    font-size: 30px;
    padding: 10px;
  }

  .banner p {
    font-size: 14px;
    padding: 5px;
  }
}
