/* Base styles for the navbar */
.navbar {
  background-color: #c1e3e6;
  height: 65px;
  width: 100%;
  padding: 0;
  margin: 0;
  display: table;
  table-layout: fixed;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.03);
}

.navbar .table-row {
  display: table-row;
  width: 100%;
}

.navbar .table-cell {
  display: table-cell;
  color: black;
  padding: 10px 0;
  font-size: 20px;
  cursor: pointer;
  font-family: "Work Sans", sans-serif;
  font-weight: 300;
  text-align: center;
  vertical-align: middle;
  background-color: white;
  position: relative; /* Required for the ::after pseudo-element */
}

/* Remove the background color change on hover */
.navbar .table-cell:hover {
  background-color: white; /* Ensures no background color change */
}

.navbar .table-cell::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 2px; /* Height of the underline */
  background-color: #00909a; /* Default color of the underline */
  transition: width 0.3s ease-in-out; /* Smooth transition effect */
}

.navbar .table-cell:hover::after {
  width: 100%; /* Full width on hover */
}

.navbar h2 {
  margin: 0;
  padding: 5px;
  font-family: "Noto Sans", sans-serif;
  font-weight: 200;
  color: black;
}

.navbar a {
  text-decoration: none;
  color: black;
  display: block;
}

/* Different solid colors for each tab */
.menuhover1:hover::after {
  background-color: #e3f6f8; /* Lightest color underline */
}

.menuhover2:hover::after {
  background-color: #c1e3e6; /* Slightly darker underline */
}

.menuhover3:hover::after {
  background-color: #00a6b2; /* Even darker underline */
}

.menuhover4:hover::after {
  background-color: #00909a; /* Darkest color underline */
}

/* Responsive Design */
@media (max-width: 800px) {
  .navbar {
    display: block;
  }

  .navbar .table-row {
    display: block;
  }

  .navbar .table-cell {
    display: block;
    width: 100%;
    padding: 10px 0;
    font-size: 18px;
  }
}

@media (max-width: 480px) {
  .navbar .table-cell {
    font-size: 16px;
    padding: 8px 0;
  }

  .navbar h2 {
    font-size: 18px;
  }
}
