.home{
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    max-width: 900px;
}

.home ul{
    text-align: left;
    margin-left: 10px;
}

.home h1{
  font-size: 3em;
}

.home li{
    padding: 10px;
}

.question button {
  background-color: #dfeff0;
  color: #00909a;
  border: none;
  border-radius: 50%; /* Set border-radius to make the button circular */
  cursor: pointer;
  font-size: 20px;
  font-family: "Work Sans", sans-serif;
  font-weight: 300;
  display: flex; /* Use flexbox for centering */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  width: 30px; /* Adjust width and height as needed */
  height: 30px;
  line-height: 1; /* Ensure proper alignment of content */
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
}
  

.home h2 {
    display: inline; /* Make <h2> elements inline */
    margin-right: 10px; /* Add some space between <h2> and buttons */
}

.question{
    margin-bottom: 20px;
}

.answer, .answer-paragraph {
  opacity: 0;
  visibility: hidden;
  transition: opacity 1s ease-in-out, visibility 1s ease-in-out;
}

.answer.show, .answer-paragraph.show {
  opacity: 1;
  visibility: visible;
}



/* Responsive Design */
@media (max-width: 768px) {
    .home {
      width: 80%;
      margin-left: auto;
      margin-right: auto;
      margin-top: 220px;
    }
  
    .home h1 {
      font-size: 30px;
      padding: 20px;
    }
  
    .home p {
      font-size: 20px;
      padding: 5px;
    }
  }
  
  @media (max-width: 480px) {
    .home {
      width: 60%;
      margin-left: auto;
      margin-right: auto;
      margin-top: 170px;
    }
  
    .home h1 {
      font-size: 24px;
      padding: 10px;
    }
  
    .home p {
      font-size: 20px;
      padding: 2px;
    }
  }
  